import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "./context/spinnerContext";
import Home from "./pages/home/Home";
import NavBarAndInfo from "./components/navbarAndInfo/NavBarAndInfo";
import WsFloating from "./components/wsFloating/WsFloating";
import Footer from "./components/footer/Footer";

function App() {

    const {spinner} = useContext (SpinnerContext);

    return (
        <BrowserRouter>
            {spinner}
            <NavBarAndInfo/>  
            <Routes>
                <Route path="/" element={<Home/>}/>        
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            <WsFloating/>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;