import "./jobsCard.css";

export default function JobsCard (props: {imgPath?: string, videoPath?: string, mainText: string, secondaryText: string}) {
    return (
        <div className="homeConcernsCard_cont">
            {props.imgPath && <img src={props.imgPath} alt="Concern" className="homeConcernsCard_img"/>}
            {props.videoPath &&
                <video muted={true} loop={true} autoPlay={true} controls={false} playsInline={true} className='homeConcernsCard_img'>
                    <source src={props.videoPath} type="video/mp4" />
                </video> 
            }
            <div className="homeConcernsCard_textCont flex column">
                <p className="homeConcernsCard_mainText">{props.mainText}</p>
                <p className="homeConcernsCard_secondaryText">{props.secondaryText}</p>
            </div>
        </div>
    );
}
