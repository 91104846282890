import "./jobs.css";
import { useRef, useEffect } from "react";
import JobsCard from "../../components/jobsCard/JobsCard";

export default function Jobs () {

    const enter = useRef (true);
    const intervalIdRef = useRef <NodeJS.Timer | undefined> (undefined);
    
    const cardsInfo = useRef <{imgPath?: string, videoPath?: string, mainText: string, secondaryText: string}[]> ([
        {
            videoPath: "/images/jobs/5.mp4",
            mainText: "Tableros generales",
            secondaryText: "En proceso"
        },
        {
            imgPath: "/images/jobs/1.jpeg",
            mainText: "Tableros generales",
            secondaryText: "Trifásico y Monofásico"
        },
        {
            imgPath: "/images/jobs/2.jpeg",
            mainText: "Colector",
            secondaryText: "Para salida de tanque"
        },
        {
            imgPath: "/images/jobs/3.jpeg",
            mainText: "BOMBA PRESURIZADORA",
            secondaryText: "En instalación de tanque"
        },
        {
            videoPath: "/images/jobs/6.mp4",
            mainText: "Instalación de tanque",
            secondaryText: "Con colector y bomba presurizadora"
        },
    ]);
  
    const handleMove = (direction: "left" | "right", manual: boolean) => {
        if (manual) clearInterval(intervalIdRef.current);
        if (!enter.current) return;
        enter.current = false;

        const homeConcernsCards_cont: HTMLDivElement | null = document.querySelector(".homeConcernsCards_cont");
        if (!homeConcernsCards_cont) return;
                                                        
        if (direction === "left") homeConcernsCards_cont?.classList.add("moveToLeft");
        if (direction === "right") homeConcernsCards_cont?.classList.add("moveToRight");

        const cardsAnimation = homeConcernsCards_cont?.getAnimations()[0];
       
        if (direction === "left") {
            cardsAnimation.addEventListener("finish", () => {
                const firstImage = homeConcernsCards_cont.firstChild as HTMLElement;
                homeConcernsCards_cont.removeChild(firstImage);
                homeConcernsCards_cont.appendChild(firstImage);
                homeConcernsCards_cont?.classList.remove("moveToLeft");
                cardsAnimation.cancel();
                enter.current = true;
            });
        } else {
            const lastImage = homeConcernsCards_cont.lastChild as HTMLElement;
            homeConcernsCards_cont.removeChild(lastImage);
            homeConcernsCards_cont.prepend(lastImage);
            cardsAnimation.addEventListener("finish", () => {
                homeConcernsCards_cont?.classList.remove("moveToRight");
                cardsAnimation.cancel();
                enter.current = true;
            });
        }
    };

    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            handleMove("left", false);
        },5000);
        
        /****************************** Eventos Touch ****************************/
        let startX: number;
        let startY: number;
        let endX: number;
        let endY: number;
        const elemento: HTMLElement | null = document.querySelector(".homeConcernsCards_cont");

        const start = (e: TouchEvent) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        };

        const end = (e: TouchEvent) => {
            endX = e.changedTouches[0].clientX;
            endY = e.changedTouches[0].clientY;
            const Ax = endX - startX;
            const Ay = Math.abs(endY - startY);
            if (Ax > 50 && Ay < 100) {
                handleMove("right", true);
            } else if (Ax < -50 && Ay < 100) {
                handleMove("left", true);
            }
        };

        elemento?.addEventListener("touchstart", start);
        elemento?.addEventListener("touchend", end);

        /**************************************************************************/

        return () => clearInterval(intervalIdRef.current);  

    }, []);
                 
    return (
        <div className="contSeccions homeConcernsCont paddingSeccions flex column" id="jobs">
            <h2 className="seccions_titles OoS"> <span className="seccions_titles_color">Rapidez</span> y profesionalismo en todos nuestros trabajos.</h2>
            <h3 className="seccions_subtitles OoS">No dudes en comunicarte con nosotros.</h3>
            <div className="homeJobsSliderCont OoS">
                <div className="homeConcernsCards_cont flex">
                    { cardsInfo.current.map((card, index) => <JobsCard imgPath={card.imgPath} videoPath={card.videoPath} mainText={card.mainText} secondaryText={card.secondaryText} key={index}/>) }
                </div>
            </div>
            <div className="homeConcernsCont_controls_cont flex">
                <div className="homeConcernsCont_control" onClick={() => handleMove("right", true)}>&#9664;</div>
                <div className="homeConcernsCont_control" onClick={() => handleMove("left", true)}>&#9654;</div>
            </div>
        </div>
    );
}
