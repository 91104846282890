import { Link } from "react-router-dom";
import "./footer.css";
import { conversionAds } from "../../utils/ads";

const Footer = () => {
    return (
        <div className="footerCont flex column">
            <div className="footerInfoCont flex">
                <Link className="navBarInfo_Logo_Cont flex" to="/">
                    <img src="/images/logos/logo1.png" alt="Logo" className="logoNavBar" />
                </Link>
                <div className="navBarInfo_Info_Cont footer_textCont flex">
                    <a className="navBar_Info_Contact_Cont flex column" href="https://wa.me/+5491137902962" target="_blank" rel="noreferrer" onClick={() => conversionAds()}>
                        <div className="flex">
                            <img src="/images/icons/phone.png" alt="Contact" className="navBar_icons" />
                            <p className="navBarInfo_Info_Large_Text">+54 9 1137902962</p>
                        </div>
                        <p className="navBarInfo_Info_Small_Text">Urgencias 24hs</p>
                    </a>
                </div>
            </div>
            <div className="footerRights flex">
                Copyright 2024. HC Urgencias. All Right Reserved.
            </div>
        </div>
    );
};

export default Footer;
