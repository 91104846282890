import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./navBar.css";
import { HashLink } from "react-router-hash-link";

import { scrollWithoffset } from "../../utils/utils";

const NavBar = () => {
    
    const thisLocation = useLocation();
    const intervalId = useRef <number | NodeJS.Timeout> (0);
        
    useEffect(() => {   

        /********************************************************** OoS ************************************************************/

        const OoS = () => {
            const OoSElements = document.querySelectorAll(".OoS") as NodeListOf<HTMLElement>;
            OoSElements.forEach((element) => {          //Mostramos el elemento cuando aparece su primer mitad
                if (((element.getBoundingClientRect().top) <= (window.innerHeight - (element.offsetHeight * (0.5))))) {
                    element.classList.add("OoSS");
                }
            });
        };
        window.addEventListener("resize", OoS);
        window.addEventListener("orientationchange", OoS);
        window.addEventListener("scroll", OoS);
        OoS();

        let tempo: NodeJS.Timeout;                      //Si estamos 1 segundos sin hacer scroll y hay alguna parte de un elemento OoS visible lo mostramos
        const handleTempo = () => {                     //Se borra el temporizador actual y se crea un nuevo cada vez que hacemos scroll
            clearTimeout(tempo);
            tempo = setTimeout(() => {
                const OoSElements = document.querySelectorAll(".OoS") as NodeListOf<HTMLElement>;
                OoSElements.forEach((element) => {
                    if (((element.getBoundingClientRect().top) < (window.innerHeight))) {
                        element.classList.add("OoSS");
                    }
                });
            }, 1000);
        };
        window.addEventListener("resize", handleTempo);
        window.addEventListener("orientationchange", handleTempo);
        window.addEventListener("scroll", handleTempo);
        handleTempo();

        /*********************************************************************************************************/

        /*** Al cambiar de pagina el scroll se va arriba ***/
        window.scrollTo({top: 0}); 

        // eslint-disable-next-line
    }, [thisLocation])
    

    /**********************************************************************************************/
            
    useEffect(() => {

        const iconoMenu = document.querySelector(".iconoMenu");
        iconoMenu?.setAttribute("name", "iconoMenu");
        const menu = document.querySelector(".menu");
        menu?.setAttribute("name", "menu");
        const opciones = document.getElementsByClassName("opcion");
        let initialWidth = window.innerWidth;
        let REM: number;
        let breakPoint = 0;
        let menuBajo = false;
        let flagEnterMenuToggle = true;
        iconoMenu?.addEventListener("click", () => {                         //Manejo de clases para bajar y subir menu
            if (flagEnterMenuToggle) {
                flagEnterMenuToggle = false;
                if (!menuBajo) {
                    menu?.classList.remove("menuSube", "menuBaja");
                    menu?.classList.add("menuBaja");
                    menuBajo = !menuBajo;    
                    const animations = menu?.getAnimations() as Animation[];
                    animations[0].addEventListener("finish", () => {
                        flagEnterMenuToggle = true;
                    });
                } else {
                    menu?.classList.remove("menuBaja", "menuSube");
                    menu?.classList.add("menuSube");
                    menuBajo = !menuBajo;  
                    const animations = menu?.getAnimations() as Animation[];
                    animations[0].addEventListener("finish", () => {
                        flagEnterMenuToggle = true;
                    });
                }
            }
        });

        const calcularREM = () => {
            if (window.innerWidth >= window.innerHeight) REM = 0.01 * window.innerHeight + 14;
            if (window.innerWidth < window.innerHeight) REM = 0.01 * window.innerWidth + 14;
            breakPoint = 55 * REM;
        };
         
        calcularREM();
        if(window.innerWidth < breakPoint) {                                        //Cargamos página con un ancho menor a BreakPoint
            const userAuthenticationComponent = document.querySelector(".userAuthenticationCont");
            menu?.classList.add("iconoMenuON", "menuOFF");
            iconoMenu?.classList.add("iconoMenuON");
            for (const opcion of opciones) {
                opcion.classList.add("opcion2");
            }
            userAuthenticationComponent?.classList.add("userAuthenticationContCenter");
        }
        
        const check = () => {
            const userAuthenticationComponent = document.querySelector(".userAuthenticationCont");
            calcularREM();           
            if(initialWidth <= breakPoint && window.innerWidth > breakPoint) {                   //Pasamos de un ancho de pantalla menor a breakPoint a uno mayor
                menu?.classList.remove("menuSube", "menuBaja", "menuOFF");
                iconoMenu?.classList.remove("iconoMenuON");
                for (const opcion of opciones) {
                    opcion.classList.remove("opcion2");
                }
                menuBajo = false;
                initialWidth = window.innerWidth;
                userAuthenticationComponent?.classList.remove("userAuthenticationContCenter");
            }     
            if(initialWidth > breakPoint && window.innerWidth < breakPoint) {                   //Pasamos de un ancho de pantalla mayor a breakPoint a uno menor
                menu?.classList.add("menuOFF");
                iconoMenu?.classList.add("iconoMenuON");
                for (const opcion of opciones) {
                    opcion.classList.add("opcion2");
                }
                initialWidth = window.innerWidth;
                userAuthenticationComponent?.classList.add("userAuthenticationContCenter");
            }     
        };        

        window.addEventListener("resize", () => check());                                        //Manejo de clases al hacer resize o cambio de orientacion
        window.addEventListener("orientationchange", () => check());
        check();

        window.addEventListener("click", (e) => {                                               //Cerramos en menu desplegado al hacer click en cualquier lado menos en el icono de menu
            const sameElement = e.target as HTMLElement;
            if (sameElement.getAttribute("name") !== "iconoMenu" && menuBajo === true) {
                menu?.classList.remove("menuBaja", "menuSube");
                menuBajo = false;
            }
        });
                
        // eslint-disable-next-line
    }, []);    
    
    /********************************** Cambio de color de opcion del navBar segun seccion elegida ********************************/

    const clearNavBarOptions = useRef(()=> {
        const navBarOptions = document.querySelectorAll(".opcion");
        for (const option of navBarOptions) {
            option.classList.remove("navBarOptionColorOn");
        }
        window.removeEventListener("scroll", clearNavBarOptions.current);
    });

    const setNavBaOption = (e: React.MouseEvent) => {
        clearInterval(intervalId.current);
        window.removeEventListener("scroll", clearNavBarOptions.current);

        const navBarOptions = document.querySelectorAll(".opcion");
        for (const option of navBarOptions) {
            option.classList.remove("navBarOptionColorOn");
        }
        const optionSetect = e.target as HTMLAnchorElement;
        optionSetect.classList.add("navBarOptionColorOn");
       
        let prevScrollY = window.scrollY;
        intervalId.current = setInterval(() => {                                    //Cada 50 ms verifica si se está haciendo scroll

            if (window.scrollY === prevScrollY) {
                window.addEventListener("scroll", clearNavBarOptions.current);  
                clearInterval(intervalId.current);
            } else {
                prevScrollY = window.scrollY;
            }
                        
        }, 50);
    };
            
    return (
        <div className="contMenu flex">
            <img className="iconoMenu" src="/images/icons/menu.png" alt="Icono Menu"/>
            <div className="tapaMenu"></div>
            <div className="menu flex">
                <HashLink className="opcion firstOption navBarOptionColorOn flex" to="/#home" scroll={(e) => scrollWithoffset(e)} onClick={setNavBaOption}>INICIO</HashLink>
                <HashLink className="opcion flex" to="/#services" scroll={(e) => scrollWithoffset(e)} onClick={setNavBaOption}>SERVICIOS</HashLink>
                <HashLink className="opcion flex" to="/#jobs" scroll={(e) => scrollWithoffset(e)} onClick={setNavBaOption}>TRABAJOS</HashLink>
                <HashLink className="opcion flex" to="/#howWork" scroll={(e) => scrollWithoffset(e)} onClick={setNavBaOption}>NOSOTROS</HashLink>
                <HashLink className="opcion flex" to="/#reviews" scroll={(e) => scrollWithoffset(e)} onClick={setNavBaOption}>RESEÑAS</HashLink>
            </div>
        </div>
    );  
};   

export default NavBar;
