export const scrollWithoffset = (e: HTMLElement) => {                                            //Ajuste de offset con el link de contacto
    const navBar = document.querySelector(".navBar_navBarInfo_Cont") as HTMLDivElement;
    const altoNavBar = navBar.offsetHeight;
    const ypos = e.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({top: ypos - altoNavBar, behavior: "smooth"});
};

export const getREM = () => window.innerWidth >= window.innerHeight ? (0.01 * window.innerHeight) + 14 : (0.01 * window.innerWidth) + 14;
    
export const waitAllImagesCharged = () : Promise <boolean> => {
    return new Promise((resolve) => {
        const images = document.querySelectorAll("img");
        const allImagesCount = images.length;
        let imagesCount = 0;
              
        images.forEach((image) => {
                                                
            if (image.complete) {                                                   //image.complete = true si la imagen se cargó completamente o dio error
                imagesCount ++;                                                     
                if (imagesCount >= allImagesCount) resolve(true);
                return;
            }
            image.addEventListener("load", () => {                                  //Evento "load": La imagen pasó de cargando a cargada
                imagesCount ++;
                if (imagesCount >= allImagesCount) resolve(true);
            });
            image.addEventListener("error", () => {                                 //Evento "error": La imagen paso de cargando a error (no se pudo cargar)
                imagesCount ++;
                if (imagesCount >= allImagesCount) resolve(true);
            });
        });                                                     
    });                                                                                  
};              

export const showElement = (condition: boolean) => {                                    //Para usar esta función correctamente tenemos que tener una clase definida con nombre "elementToShow" y que tenga "opacity: 0"
    if (!condition) return;
    const elementsToShow = document.querySelectorAll(".elementToShow") as NodeListOf<HTMLElement>;
    if (elementsToShow) elementsToShow.forEach(element => element.style.opacity = "1");
};