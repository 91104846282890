import "./howWork.css";

const HowWork = () => {
       
    return (
        <div className="contSeccions howWorkSeccion paddingSeccions flex column" id="howWork">
            <div className="reviewsPage_bg_cont">
                <div className="reviewsPage_bg_filter"></div>
                <img src="/images/howwork/bg.jpg" className="reviewsPage_bg_img howWorkSeccion_bg_img" alt="Background" />
            </div>
            <h2 className="seccions_titles howWorkSeccion_title OoS">En <span className="seccions_titles_color">HC Urgencias</span> nos destacamos <br /> por la rapidez y calidad de nuestro servicio.</h2>
            <h3 className="seccions_subtitles howWorkSeccion_subTitle OoS">Atendemos urgencias los 7 días de la semana, las 24hs del día en Capital Federal y Gran Buenos Aires.</h3>
            <div className="howWorkSeccion_divs_cont flex OoS">
                <div className="howWorkSeccion_div howWorkSeccion_div1 flex column">
                    <div className="howWorkSeccion_div1_card_cont flex column">
                        <img src="/images/howwork/24hs.png" className="howWorkSeccion_div1_card_img" alt="24hs" />
                        <p className="howWorkSeccion_div1_card_text">Urgencias las 24hs</p>
                    </div>
                    <div className="howWorkSeccion_div1_card_cont howWorkSeccion_div1_card_center flex column">
                        <img src="/images/howwork/7days.png" className="howWorkSeccion_div1_card_img" alt="24hs" />
                        <p className="howWorkSeccion_div1_card_text">De lunes a domingo</p>
                    </div>
                    <div className="howWorkSeccion_div1_card_cont flex column">
                        <img src="/images/howwork/location.png" className="howWorkSeccion_div1_card_img" alt="24hs" />
                        <p className="howWorkSeccion_div1_card_text">Capital Federal <br />y Gran Buenos Aires</p>
                    </div>
                </div>
                <div className="howWorkSeccion_div howWorkSeccion_div2 flex column">
                    <img src="/images/howwork/van.png" className="howWorkSeccion_vanImg" alt="Movil" />
                </div>
            </div>
        </div>
    );
};

export default HowWork;
