export const conversionAds = (url?: Location | (string & Location)) => {
    const callback = function () {
        if (typeof (url) != "undefined") {
            window.location = url;
        }
    };
    window.gtag("event", "conversion", {
        "send_to": "AW-16729191222/75q3CLOZndoZELbmjKk-",
        "event_callback": callback
    });
    return false;
};

