import "./services.css";

type CardData = {title: string, subtitles: string[], imgSrc: string, iconSrc: string};

const cardsData: CardData[] = [
    {
        title: "Trabajos de Electricidad",
        subtitles: ["Reparaciones domiciliarias", "Instalaciones eléctricas en general", "Tableros", "Urgencias las 24hs"],
        imgSrc: "/images/carousel/electrician.jpg",
        iconSrc: "/images/icons/electrician.png"
    },
    {
        title: "Plomería en general",
        subtitles: ["Reparaciones domiciliarias", "Instalaciones de gas", "Instalaciones de agua", "Urgencias las 24hs"],
        imgSrc: "/images/carousel/plumber.jpg",
        iconSrc: "/images/icons/plumbing.png"
    },
    {
        title: "Destapaciones",
        subtitles: ["Urgencias las 24hs", "Destapaciones de cloacas", "Destapaciones de pluviales", "Destapaciones de desagües"],
        imgSrc: "/images/carousel/unlock.jpg",
        iconSrc: "/images/icons/unlock.png"
    },
];

const CardServices = (props: CardData) => {
    const {title, subtitles, imgSrc, iconSrc} = props;
    return (
        <div className="services_card_cont flex column OoS">
            <img src={imgSrc} className="services_card_img" alt="Servicios" />
            <div className="services_card_text_cont flex column">
                <img src={iconSrc} className="services_card_icon" alt="Electricidad" />
                <h2 className="services_card_text_title">{title}</h2>
                <div className="services_card_text_subtitles_cont flex">
                    <ul>
                        {subtitles.map((subtitle, index) => (
                            <li key={index} className="flex">{subtitle}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Services = () => {
    return (
        <div className="contSeccions services_seccion_cont paddingSeccions flex wrap" id="services">
            {
                cardsData.map((cardData, index) => 
                    <CardServices key={index} {...cardData}/>                    
                )    
            }
        </div>
    );
};

export default Services;
