import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./navBarAndInfo.css";
import NavBar from "../navBar/NavBar";
import { conversionAds } from "../../utils/ads";

function NavBarAndInfo () {

    useEffect(() => {
        const adjustPaddingNavBar = () => {
            const logoNavBar = document.querySelector(".logoNavBar");
            const navBarInfo_Logo_Cont_LEFT = logoNavBar?.getBoundingClientRect().left;
            const contMenu = document.querySelector(".contMenu") as HTMLDivElement;
            contMenu.style.paddingLeft = navBarInfo_Logo_Cont_LEFT + "px";
        };
        adjustPaddingNavBar();
        window.addEventListener("resize", adjustPaddingNavBar);
        window.addEventListener("orientationchange", adjustPaddingNavBar);
        window.addEventListener("scroll", adjustPaddingNavBar);
        window.addEventListener("load", adjustPaddingNavBar);
    }, []);
    
    return (
        <div className="navBar_navBarInfo_Cont elementToShow">
            <div className="navBarInfo_Cont flex">
                <Link className="navBarInfo_Logo_Cont flex" to="/">
                    <img src="/images/logos/logo1.png" alt="Logo" className="logoNavBar"/>
                </Link>
                <div className="navBarInfo_Info_Cont flex">
                    <a className="navBar_Info_Contact_Cont flex column" href="https://wa.me/+5491137902962" target="_blank" rel="noreferrer" onClick={() => conversionAds()}>
                        <div className="flex">
                            <img src="/images/icons/phone.png" alt="Contact" className="navBar_icons"/>
                            <p className="navBarInfo_Info_Large_Text">+54 9 1137902962</p>
                        </div>
                        <p className="navBarInfo_Info_Small_Text">Urgencias 24hs</p>
                    </a>
                </div>
            </div>
            <div className="navBar_Cont">
                <NavBar/>
            </div>
        </div>
    );
}

export default NavBarAndInfo;