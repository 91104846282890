import { conversionAds } from "../../utils/ads";
import "./wsFloating.css";

function WsFloating() {
    return (
        <a href="https://wa.me/+5491137902962" target="_blank" rel="noreferrer" className="wsFloatingCont" onClick={() => conversionAds()}>
            <img src="/images/icons/whatsapp.png" alt="WhatsApp" className="wsFloating"/>
        </a>
    );
}

export default WsFloating;