import { useEffect, useContext } from "react";
import CarouselZoom from "../../components/carouselZoom/CarouselZoom";
import Services from "../services/Services";
import "./home.css";
import Jobs from "../jobs/Jobs";
import Reviews from "../reviews/Reviews";
import { showElement, waitAllImagesCharged } from "../../utils/utils";
import { SpinnerContext } from "../../context/spinnerContext";
import HowWork from "../howWork/HowWork";

function Home() {
    
    const {showSpinner} = useContext(SpinnerContext);

    useEffect(() => {
        (async() => {
            showSpinner(true);
            await waitAllImagesCharged();
            showSpinner(false);
            showElement(true);
        })();
    }, []);
    
    return (
        <div className="home elementToShow">
            <CarouselZoom/>
            <Services/>
            <Jobs/>
            <HowWork/>
            <Reviews/>
        </div>
    );
}

export default Home;