import { useEffect, useRef } from "react";
import { getREM } from "../../utils/utils";
import "./reviews.css";

let sliderInMovement = false;
const sliderCardsMarginInRem = 2;
const isPortrait = () => window.innerWidth < window.innerHeight;

const Reviews = () => {
    const intervalID = useRef <NodeJS.Timer> ();

    const sliderHandleMove = (direction: "left" | "right", stopAutoMove?: boolean) => {
        if (sliderInMovement) return;
        if (stopAutoMove) clearInterval(intervalID.current);
        sliderInMovement = true;
        const sliderCont = document.querySelector(".reviewsPage_slider_internalCont") as HTMLDivElement;
        const distanceToMove = sliderCont.querySelector("div")?.offsetWidth;
        if (!distanceToMove) return;
        document.documentElement.style.setProperty("--slider2translateDistance", `${distanceToMove + (sliderCardsMarginInRem * 2 * getREM())}px`);
        const cards = sliderCont.querySelectorAll(".reviewsPage_card_cont") as NodeListOf<HTMLDivElement>;
        cards.forEach(card => card.classList.remove("reviewsPage_card_cont_scale"));

        if (direction === "left") {
            sliderCont.classList.add("reviewsPage_slider_toLeftAnimation");

            if (!isPortrait()) {
                cards[2].classList.add("reviewsPage_card_cont_scale");
            } else {
                cards[1].classList.add("reviewsPage_card_cont_scale");
            }
            
            const animation = sliderCont.getAnimations()[0];
            animation.addEventListener("finish", () => {
                sliderCont.classList.remove("reviewsPage_slider_toLeftAnimation");
                const firstChild = sliderCont.firstChild;
                if (!firstChild) return;
                sliderCont.appendChild(firstChild);
                sliderInMovement = false;
            });
        } else {
            sliderCont.classList.add("reviewsPage_slider_toRightAnimation");
            const animation = sliderCont.getAnimations()[0];
            const lastChild = sliderCont.lastChild;
            if (!lastChild) return;
            sliderCont.prepend(lastChild);

            if (!isPortrait()) {
                cards[0].classList.add("reviewsPage_card_cont_scale");
            } else {
                cards[cards.length - 1].classList.add("reviewsPage_card_cont_scale");
            }
            
            animation.addEventListener("finish", () => {
                sliderCont.classList.remove("reviewsPage_slider_toRightAnimation");
                sliderInMovement = false;
            });
        }
    };

    useEffect(() => {

        intervalID.current = setInterval(() => {
            sliderHandleMove("left");
        }, 6000);

        /*******************************************************************************************/

        let startX: number;
        let startY: number;
        let endX: number;
        let endY: number;
        const elemento: HTMLElement | null = document.querySelector(".reviewsPage_slider_cont");

        const start = (e: TouchEvent) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        };

        const end = (e: TouchEvent) => {
            endX = e.changedTouches[0].clientX;
            endY = e.changedTouches[0].clientY;
            const Ax = endX - startX;
            const Ay = Math.abs(endY - startY);
            if (Ax > 50 && Ay < 100) {
                sliderHandleMove("right", true);
            } else if (Ax < -50 && Ay < 100) {
                sliderHandleMove("left", true);
            }
        };

        elemento?.addEventListener("touchstart", start);
        elemento?.addEventListener("touchend", end);
        
        /*******************************************************************************************/

        const adjustSlider = () => {                                                                                                    //Ajuste inicial del slider o al cambiar de orientacion o redimensionar
            document.documentElement.style.setProperty("--reviewsPage_card_cont_margin", `${sliderCardsMarginInRem * getREM()}px`);     // setea la card activa
            const sliderCont = document.querySelector(".reviewsPage_slider_internalCont") as HTMLDivElement;
            const cards = sliderCont.querySelectorAll(".reviewsPage_card_cont") as NodeListOf<HTMLDivElement>;
            cards.forEach(card => card.classList.remove("reviewsPage_card_cont_scale"));

            isPortrait() ? cards[0].classList.add("reviewsPage_card_cont_scale") : cards[1].classList.add("reviewsPage_card_cont_scale");
        };

        window.addEventListener("orientationchange", adjustSlider);
        window.addEventListener("resize", adjustSlider);
        adjustSlider();

    }, []);    
    
    return (
        <div className="contSeccions reviewsPageSeccion paddingSeccions flex column" id="reviews">
            <div className="reviewsPage_bg_cont">
                <div className="reviewsPage_bg_filter"></div>
                <img src="/images/reviews/bg1.jpg" className="reviewsPage_bg_img" alt="Background" /> 
            </div>
            <div className="reviewsPage_text_cont">
                <p className="reviewsPage_title OoS"><span>Opiniones</span> de nuestros clientes</p>
            </div>
            <div className="reviewsPage_slider_cont">
                <div className="reviewsPage_slider_internalCont flex">
                    <div className="reviewsPage_card_cont flex column">
                        <div className="flex">
                            <img src="/images/icons/woman.png" alt="Review" />
                            <h3>Carolina</h3>   
                        </div>
                        <p><span>&quot; </span>Excelente servicio! Rápidos y profesionales. Me solucionaron un problema eléctrico que nadie más pudo resolver. ¡Muy recomendados!<span> &quot;</span></p>
                    </div>
                    <div className="reviewsPage_card_cont flex column">
                        <div className="flex">
                            <img src="/images/icons/man.png" alt="Review" />
                            <h3>Alfredo</h3>   
                        </div>
                        <p><span>&quot; </span>Atención impecable y trabajo de calidad. Llegaron a tiempo y dejaron todo perfecto. Sin dudas los volveré a llamar. Gracias!<span> &quot;</span></p>
                    </div>
                    <div className="reviewsPage_card_cont flex column">
                        <div className="flex">
                            <img src="/images/icons/woman.png" alt="Review" />
                            <h3>Florencia</h3>   
                        </div>
                        <p><span>&quot; </span>¡Gran equipo! Solucionaron una fuga de agua en minutos y el precio fue justo. Profesionales y confiables. Muy conforme<span> &quot;</span></p>
                    </div>
                    <div className="reviewsPage_card_cont flex column">
                        <div className="flex">
                            <img src="/images/icons/man.png" alt="Review" />
                            <h3>Ariel</h3>   
                        </div>
                        <p><span>&quot; </span>Destaparon la cañería de mi cocina en poco tiempo. Todo quedó como nuevo. Muy amables y eficientes. ¡Cinco estrellas!<span> &quot;</span></p>
                    </div>
                </div>
            </div>
            <div className="homeConcernsCont_controls_cont flex">
                <div className="homeConcernsCont_control reviewsPage_slider_control" onClick={() => sliderHandleMove("right", true)}>&#9664;</div>
                <div className="homeConcernsCont_control reviewsPage_slider_control" onClick={() => sliderHandleMove("left", true)}>&#9654;</div>
            </div>
        </div>
    );
};

export default Reviews;
